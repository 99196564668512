import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { generateToken } from '../../utility/Token';
import { reloadTransaction } from '../../stores/transaction/Transaction';
import { toast, ToastContainer } from 'react-toastify';
import './PayMobile.css';



const PayMobile = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const productList = useSelector((state: NaTypes.Store) => state.transaction.productList);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);

    const [amount, setAmount] = useState<number>(0);
    const [isProductExpanded, setIsProductExpanded] = useState(true);
    const [isStandard, setIsStandard] = useState('');
    const [qrCodeBank, setQrCodeBank] = useState('');
    const [selectedProductId, setSelectedProductId] = useState<string>('');

    const at = accessData.accessToken ? accessData.accessToken : '';
    const aid = accessData.accountId ? accessData.accountId : '';
    const acc = accessData.account ? accessData.account : '';
    const token = generateToken(aid, at);
    const currentTransaction = transaction.transactionList.find((t) => t.status === '3' || t.status === '4');
    const activePremium = transaction.transactionList.find((t) => t.status === '1');
    const validPackageIds = productList.map((p: any) => p.product_id);
    const defaultPackageId = validPackageIds[0];

    const sortedProductList = productList.slice().sort((a, b) => b.product_id - a.product_id);
    const displayedProducts = isProductExpanded ? sortedProductList : sortedProductList.slice(2, 5);



    const changePlan = async (selectPlan: string) => {
        const product = productList.find((t) => t.product_id === selectPlan);
        const formData = new URLSearchParams();

        formData.append('access_token', token); formData.append('accountId', aid);
        formData.append('transactionId', currentTransaction.transaction_id); formData.append('packageId', product.product_id);

        await fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/paymentSystem.banktranfer.change`, {
            method: 'POST', body: formData, headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });

        dispatch(reloadTransaction(at, aid));
        setAmount(parseInt(product.price)); setSelectedProductId(selectPlan); setQrCodeBank("");
    };

    const createPrePayment = async () => {
        if (activePremium || currentTransaction || !defaultPackageId) return;

        const formData = new URLSearchParams({
            access_token: token, accountId: aid, fullName: acc.fullname, email: acc.email, phone: acc.phone,
            from: "Mobile Web Payment: Free User", packageId: isStandard || defaultPackageId
        });

        try {
            await fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/paymentSystem.banktranfer.one`, {
                method: 'POST', body: formData, headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });
            omiseQR();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const omiseQR = () => {
        if (qrCodeBank === "") {
            window.Omise.setPublicKey(process.env.REACT_APP_OMISE_KEY);
            window.Omise.createSource('promptpay',
                { "amount": amount * 100, "currency": "THB" },
                (statusCode: any, response: any) => {
                    if (statusCode === 200) {
                        omiseQRHandler(response);
                    } else {
                        toast.warning("ขณะนี้ QRCode มีปัญหา กรุณาเลือกช่องทางชำระอื่น");
                    }
                }
            );
        }
    };

    const omiseQRHandler = async (resOmiseSource: any) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/omise/charge`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json', 'Content-Type': 'application/json', 'token': token
                },
                body: JSON.stringify({
                    source: resOmiseSource,
                    transaction_id: currentTransaction.transaction_id,
                    return_uri: `${process.env.REACT_APP_WEB_URL}/payment-history`,
                }),
            });

            const res = await response.json();
            if (res.error === 0) setQrCodeBank(res.data.source.scannable_code.image.download_uri);

        } catch (err) {
            console.error(err); toast.warning("ขณะนี้ QRCode มีปัญหา กรุณาเลือกช่องทางชำระอื่น");
        }
    };



    useEffect(() => {
        dispatch(reloadTransaction(at, aid));
        const params = new URLSearchParams(location.search);
        if (params.has('mobile-1')) setIsStandard(params.get('mobile-1') || "");
    }, []);

    useEffect(() => {
        const interval = setInterval(() => { dispatch(reloadTransaction(at, aid)); }, 6000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (productList.length > 0 && !selectedProductId && !currentTransaction) {
            setSelectedProductId(productList[0].product_id); setAmount(parseInt(productList[0].price));
        }
    }, [productList]);

    useEffect(() => { if (amount && qrCodeBank === "") omiseQR(); }, [amount, qrCodeBank]);

    useEffect(() => {
        if (currentTransaction) {
            setSelectedProductId(currentTransaction.product_id); setAmount(parseInt(currentTransaction.amount));
        } else {
            createPrePayment();
        }
    }, [currentTransaction, defaultPackageId, isStandard]);

    useEffect(() => {
        if (!accessData.accessToken) {
            history.push('/');
        } else if ((currentTransaction && currentTransaction.status !== '3') || activePremium) {
            history.push('/payment-history');
        }
    }, [accessData, activePremium, currentTransaction, history]);



    return (
        <div>
            <div className="mobile-pay-bg-sign-up">
                <div className="row">

                    <div className="mobile-pay-header col-12 pt-4"><h3>สมัครใช้บริการ</h3></div>

                    <div className="mobile-pay-step-2 col-12">
                        <div className="mobile-pay-premium-text-payment col-12">
                            <div>เลือกคอร์สเรียน</div>

                            <div className="mobile-pay-product-selection">
                                {displayedProducts.map((product) => {
                                    const basePrice = parseFloat(product.price);
                                    const monthlyPrice = basePrice / product.subscription_months;

                                    const lastProduct = sortedProductList[sortedProductList.length - 1];
                                    const lastBasePrice = parseFloat(lastProduct.price);
                                    const lastMonthlyPrice = lastBasePrice / lastProduct.subscription_months;

                                    const savingsPercentage = lastMonthlyPrice > 0
                                        ? Math.ceil((1 - (monthlyPrice / lastMonthlyPrice)) * 100)
                                        : 0;

                                    return (
                                        <div
                                            key={product.product_id}
                                            className={`mobile-pay-product-item ${selectedProductId === product.product_id ? 'selected' : ''}`}
                                            onClick={() => changePlan(product.product_id)}
                                        >
                                            <div className="mobile-pay-product-name">
                                                <label className="mobile-pay-product-radio">
                                                    <input
                                                        type="radio" name="product" value={product.product_id}
                                                        checked={selectedProductId === product.product_id} readOnly
                                                    />
                                                    {product.description_th}
                                                </label>
                                                {Number(savingsPercentage) > 0 && (
                                                    <div className="mobile-pay-savings-percentage">
                                                        ประหยัด {Number(savingsPercentage)}%
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mobile-pay-product-pricing">
                                                <div className="mobile-pay-monthly-price">
                                                    ฿ {Math.ceil(monthlyPrice).toLocaleString(undefined, { maximumFractionDigits: 2 })} ต่อเดือน
                                                </div>
                                                <div className="mobile-pay-total-price">
                                                    ทั้งหมด ฿ {basePrice.toLocaleString()}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {sortedProductList.length > 3 && (
                                    <div onClick={() => setIsProductExpanded(!isProductExpanded)} className="mobile-pay-view-all-button">
                                        {isProductExpanded ? 'ปิด' : 'ดูทั้งหมด'}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="mobile-pay-price-display col-12">
                            ยอดที่ต้องชำระ – {amount.toLocaleString()} บาท
                        </div>

                        <div className="mobile-pay-qr col-12">
                            <img src={qrCodeBank} alt={`PromptPay QR code for ${amount} baht`} />
                        </div>

                        <div className="mobile-pay-notes col-12">
                            <p>ชำระผ่าน Mobile Banking ได้โดย</p>
                            <ul className="mobile-pay-notes-list">
                                <li>Capture หน้าจอโทรศัพท์</li>
                                <li>นำภาพ QR ชำระผ่าน App ของธนาคาร</li>
                                <li>เมื่อชำระเงิน เราถือว่าคุณได้ยอมรับ <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/terms-and-conditions">เงื่อนไข </a>และ<a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/privacy-and-cookie-policy"> ข้อตกลงการใช้บริการ</a> ของเราแล้ว</li>
                                <li>ระบบจะเปลี่ยนหน้าอัตโนมัติ หลังการชำระเงินเสร็จสิ้น</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <ToastContainer
                position="top-center" autoClose={3000} hideProgressBar newestOnTop
                closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export default PayMobile;
