import React from 'react';
import facebookFIcon from '../../../images/facebook/facebook_f.svg';

interface NaLoginStepLoginProps {
    phone: string;
    setPhone: React.Dispatch<React.SetStateAction<string>>;
    setState: React.Dispatch<React.SetStateAction<string>>;
    requestOtp: (phoneNumber: string) => Promise<any>;
    logInFacebook: () => void;
}



const NaLoginStepLogin: React.FC<NaLoginStepLoginProps> = ({ phone, setPhone, setState, requestOtp, logInFacebook }) => {

    const isMobile = window.innerWidth <= 575;
    const title = isMobile ? 'ลงชื่อเข้าใช้งาน' : 'ลงชื่อเข้าใช้งาน | สร้างบัญชีฟรี';



    const handleLoginClick = async () => {
        if (phone) {
            const res = await requestOtp(phone);
            if (res.success) setState('VERIFY_OTP');
        }
    };



    return (
        <div className="login-bg">
            <div className="row p-4">
                <div className="col-12">
                    <h3 className="text-center register-title">{title}</h3>
                </div>

                <div className="col-12 mobile-number-input">
                    <div className="row center">
                        <input
                            id="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="กรอกเบอร์โทรศัพท์"
                            type="tel"
                            inputMode="numeric"
                            maxLength={10}
                        />
                    </div>
                </div>

                <div className="col-12 mobile-number-detail">
                    <p className="login-text login-description">
                        ลูกค้าจำเป็นต้องใช้เบอร์โทรศัพท์หรือบัญชีผู้ใช้ Facebook เพื่อลงทะเบียนสร้างบัญชีของ <b>NockAcademy</b> และ <b>Login</b> เข้าใช้งานบริการของเรา
                    </p>
                    <p className="login-text login-description">
                        <span>หากลูกค้ากดลงชื่อเข้าใช้งานด้วยวิธีใดวิธีหนึ่งด้านล่างต่อไปนี้ นั่นคือลูกค้าได้ยอมรับ </span>
                        <a href="https://nockacademy.com/index.php/terms-and-conditions/" rel="noopener noreferrer" target="_blank">
                            ข้อกำหนดในการให้บริการ
                        </a>
                        <span> และ </span>
                        <a href="https://nockacademy.com/index.php/privacy-and-cookie-policy" rel="noopener noreferrer" target="_blank">
                            นโยบายความเป็นส่วนตัว
                        </a>
                        <span> ของ <b>NockAcademy</b> แล้ว</span>
                    </p>
                </div>

                <div className="col-12">
                    <button id="login-button2" onClick={handleLoginClick}>
                        <p className="m-0">ลงชื่อเข้าใช้งาน</p>
                    </button>
                </div>

                <div className="col-12 center register-line">
                    <p className="line"></p>
                </div>

                <div className="col-12">
                    <p className="login-text">เข้าใช้งานด้วยวิธีอื่น</p>
                </div>

                {isMobile ? (
                    <div className="col-12">
                        <div className="login-facebook-button2 can-click" onClick={() => { logInFacebook(); }}>
                            <img className="fa-facebook" src={facebookFIcon} alt="Facebook Icon" />
                            ผ่านเฟสบุ๊ค
                        </div>
                    </div>
                ) : (
                    <div className="col-12">
                        <div className="login-facebook-button2 can-click" onClick={() => { logInFacebook(); }}>
                            <img className="fa-facebook" src={facebookFIcon} alt="Facebook Icon" />
                            ลงชื่อเข้าใช้งานผ่านเฟสบุ๊ค
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default NaLoginStepLogin;
