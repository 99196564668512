import { generateToken } from '../../utility/Token';

const initialState: NaTypes.LiveStreamTableList = {
    state: '',
    currentLS: null,
    list: [],
};

const fetchLiveStreamTableList = () => {
    return (dispatch: any) => {
        dispatch(fetchLiveStreamTablePending());
        fetch(`${process.env.REACT_APP_API_URL}/api/v8/method/videolesson?`)
            .then((res) => res.json())
            .then((res) => {

                if (res.error) {
                    throw (res.error);
                }
                const ls = res.data.map((d: any): NaTypes.LiveStream => {
                    let lsMap = {
                        id: d.id,
                        urlVideo: d.url_video_for_web,
                        scheduleImagePath: d.schedule_image_path,
                        questions: [],
                        type: d.video_type,
                        status: d.status_stop,
                        subject: d.subject,
                        subjectId: d.subject_id,
                        subjectDescriptionTh: d.subject_description_th,
                        startTime: d.starttime,
                        endTime: d.endtime,
                        instructorName: d.instructor_name,
                        grade: d.grade,
                        document: d.img_document,
                        slide: d.slide_document,
                        isFree: d.is_free,
                        actualStartTime: d.actual_starttime,
                        runTime: d.runtime,
                        notification: false,
                        detail: d.detail,
                        description: d.description,
                        subscriber: (d.subscriber == null) ? 0 : parseInt(d.subscriber),
                        duration: (d.video_duration == null) ? 0 : parseInt(d.video_duration),
                        videoId: d.video_id,
                        gradeDescriptionTh: d.grade_description_th,
                        perfectScoreStatus: d.perfect_score_status == 1 ? true : false,
                        slowMode: d.slow_mode,
                        newUpdate: d.new_update == 1 ? true : false,
                        invitational: d.invitational,
                        rankingScoreStatus: d.ranking_status == 1 ? true : false,
                        rankingAmount: d.ranking_amount,
                        blurStatus: d.blur_status == 1 ? true : false
                    };
                    if (d.questions) {
                        lsMap.questions = d.questions.map((q: any): LiveQuestion => {
                            let quiz = {
                                id: q.id,
                                value: q.value,
                                imagePath: q.image_path,
                                image_path: q.image_path,
                                answers: []
                            }
                            if (q.answers) {
                                quiz.answers = q.answers.map((a: any): LiveQuestionAnswer => ({
                                    id: a.id,
                                    imagePath: a.image_path,
                                    image_path: q.image_path,
                                    isCorrect: a.is_correct,
                                    value: a.value,
                                }));
                            }
                            return quiz;
                        });
                    }
                    return lsMap;
                });
                dispatch(fetchLiveStreamTableSuccess(ls));
                if (ls.length > 0) {
                    dispatch(liveStreamSelect(ls[0]));
                }
            })
            .catch(() => {
                dispatch(fetchLiveStreamTableError());
            });
    };
};

const fetchLiveStreamById = (id: string, accessData: NaTypes.Authenticate) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v8/method/videolesson?lesson_id=${id}${accessData.accessToken && accessData.accountId ? "&account_id=" + accessData.accountId + "&access_token=" + generateToken(accessData.accountId, accessData.accessToken) : ""}`)
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                return res;
                // throw (res.error);
            }
            const ls = res.data.map((d: any): NaTypes.LiveStream => {
                return {
                    id: d.id,
                    urlVideo: d.url_video_for_web,
                    scheduleImagePath: d.schedule_image_path,
                    questions: d.questions.map((q: any): LiveQuestion => ({
                        id: q.id,
                        value: q.value,
                        imagePath: q.image_path,
                        image_path: q.image_path,
                        answers: q.answers.map((a: any): LiveQuestionAnswer => ({
                            id: a.id,
                            imagePath: a.image_path,
                            image_path: q.image_path,
                            isCorrect: a.is_correct,
                            value: a.value,
                        })),
                    })),
                    type: d.video_type,
                    status: d.status_stop,
                    subject: d.subject,
                    subjectId: d.subject_id,
                    subjectDescriptionTh: d.subject_description_th,
                    startTime: d.starttime,
                    endTime: d.endtime,
                    instructorName: d.instructor_name,
                    grade: d.grade,
                    document: d.img_document,
                    slide: d.slide_document,
                    isFree: d.is_free,
                    actualStartTime: d.actual_starttime,
                    runTime: d.runtime,
                    notification: false,
                    detail: d.detail,
                    description: d.description,
                    duration: null,
                    subscriber: 0,
                    videoId: null,
                    gradeDescriptionTh: d.grade_description_th,
                    perfectScoreStatus: d.perfect_score_status == 1 ? true : false,
                    slowMode: d.slow_mode,
                    newUpdate: d.new_update == 1 ? true : false,
                    invitational: d.invitational,
                    rankingScoreStatus: d.ranking_status == 1 ? true : false,
                    rankingAmount: d.ranking_amount,
                    blurStatus: d.blur_status == 1 ? true : false
                };
            });
            if (ls.length > 0) {
                return ls[0];
            } else {
                return null;
            }
        })
        .catch(() => { });
};

const FETCH_LIVE_STREAM_TABLE_LIST_PENDING = 'FETCH_LIVE_STREAM_TABLE_LIST_PENDING';
const fetchLiveStreamTablePending = () => {
    return {
        type: FETCH_LIVE_STREAM_TABLE_LIST_PENDING,
    };
};

const FETCH_LIVE_STREAM_TABLE_LIST_SUCCESS = 'FETCH_LIVE_STREAM_TABLE_LIST_SUCCESS';
const fetchLiveStreamTableSuccess = (list: any) => {
    return {
        type: FETCH_LIVE_STREAM_TABLE_LIST_SUCCESS,
        list,
    };
};

const FETCH_LIVE_STREAM_TABLE_LIST_ERROR = 'FETCH_LIVE_STREAM_TABLE_LIST_ERROR';
const fetchLiveStreamTableError = () => {
    return {
        type: FETCH_LIVE_STREAM_TABLE_LIST_ERROR,
    };
};

const LIVE_STREAM_SELECT = 'LIVE_STREAM_SELECT';
const liveStreamSelect = (liveStream: NaTypes.LiveStream | undefined) => {
    return {
        type: LIVE_STREAM_SELECT,
        liveStream,
    };
};

export {
    fetchLiveStreamById,
    fetchLiveStreamTableList,
    liveStreamSelect,
};

/**
 * schedule live stream list state
 * @param {Array<any>} state
 * @param {any} action
 * @return {Array<any>} current state
 */
export function LiveStreamTableListState(
    state: NaTypes.LiveStreamTableList = initialState,
    action: any): NaTypes.LiveStreamTableList {
    switch (action.type) {
        case FETCH_LIVE_STREAM_TABLE_LIST_PENDING:
            return {
                state: 'PENDING',
                list: [],
            };
        case FETCH_LIVE_STREAM_TABLE_LIST_SUCCESS:
            return {
                state: 'SUCCESS',
                currentLS: null,
                list: action.list,
            };
        case FETCH_LIVE_STREAM_TABLE_LIST_ERROR:
            return {
                state: 'ERROR',
                list: [],
            };
        case LIVE_STREAM_SELECT:
            return Object.assign({}, state, {
                currentLS: action.liveStream,
            });
        default:
            return state;
    }
};
