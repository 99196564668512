import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateToken } from '../../../utility/Token';
import { cancelRequestLogin } from '../../../stores/authorization/AuthorizationState';
import NaLoginOfferItem from './NaLoginOfferItem';
import closeImg from '../../../images/actions/close.svg';



const NaLoginStepOffer = () => {
    const title = "บริการของเรา";
    const dispatch = useDispatch();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const [productList, setProductList] = useState<Array<any>>([]);
    const [show, setShow] = useState(true);



    const reloadTransaction = async (accessToken: string, accountId: string) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/api/v4/method/paymentSystem?access_token=${generateToken(accountId, accessToken)}&accountId=${accountId}&all=true`;
            const response = await fetch(url, { method: 'GET' });

            if (!response?.ok) {
                throw new Error(`Failed to fetch payment system. Status: ${response?.status}`);
            }

            const data = await response.json();

            setProductList(data.product_list.sort((a: { product_id: number }, b: { product_id: number }) => b.product_id - a.product_id));
        } catch (error) {
            console.error(`Cannot load payment system: ${(error as Error).message}`);
        }
    };

    const closeOfferModal = () => {
        setShow(false);
        dispatch(cancelRequestLogin())
    }



    useEffect(() => {
        const { accessToken, accountId } = accessData;
        reloadTransaction(`${accessToken}`, `${accountId}`);
    }, []);

    useEffect(() => {
        const scrollContainer = document.querySelector('.login-offer-list-container') as HTMLElement;
        if (scrollContainer) {
            const centerPosition = scrollContainer.scrollWidth / 2 - scrollContainer.offsetWidth / 2;
            scrollContainer.scrollLeft = centerPosition;
        }
    }, [productList]);



    const lastThreeProducts = productList.slice(-3);

    return !show ? null : (
        <div className="login-bg login-offer-bg">
            <div className="row p-4 login-offer-container">
                <img
                    className="login-offer-close can-click"
                    src={closeImg}
                    alt="Close Button"
                    onClick={() => closeOfferModal()}
                />

                <div className="col-12">
                    <h3 className="text-center login-offer-title">{title}</h3>
                </div>

                <div className="login-offer-description">
                    <ul>
                        <li>เรียนได้ครบสี่วิชา วิทย์ คณิต อังกฤษ ไทย</li>
                        <li>มีบทเรียนมากกว่า <b style={{ color: "#f97d5e" }}>2,000+ คลิป, แบบฝึกหัด ข้อสอบ 4,000+ บท, ไลฟ์สอนสด, ไลฟ์ย้อนหลัง</b></li>
                        <li><b style={{ color: "#f97d5e" }}>รับชมไลฟ์สอนสด</b> และ <b style={{ color: "#f97d5e" }}>เข้าทำข้อสอบ</b> ได้ทุกอุปกรณ์</li>
                    </ul>
                </div>

                <div className="login-offer-list-container">
                    {lastThreeProducts.map((product, index) => (
                        <div className="login-offer-item" key={index}>
                            <NaLoginOfferItem product={product} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NaLoginStepOffer;
