import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generateToken } from '../../utility/Token';
import { toast } from 'react-toastify';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import userProfile from '../../images/user/user.svg';

interface MessageProps { message: MessageItem; deleteMessage: (key: string) => void; blur: boolean }



const Message: React.FC<MessageProps> = ({ message, deleteMessage, blur }) => {

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
    const [randomText, setRandomText] = useState<string>('');

    const aid = accessData.accountId ? accessData.accountId : '';
    const isAdmin = aid === process.env.REACT_APP_ADMIN_ACCOUNT;
    const blurForUser = blur && !isAdmin;
    const open = Boolean(anchorEl);

    const options = [
        { value: 'delete', text: 'ลบ' },
    ];

    const adminOptions = [
        { value: 'delete', text: 'ลบ' },
        { value: 'ban', text: 'แบน' },
    ];

    const handleClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        !open ? setAnchorEl(event.currentTarget) : handleClose();
    };

    const handleSelect = (value: string, key: string, userId: string) => {
        switch (value) {
            case 'delete':
                deleteMessage(key);
                break;
            case 'ban':
                banUser(userId);
                break;
        }
    };

    const handleClose = () => setAnchorEl(null);

    const banUser = async (userId: string) => {
        if (!accessData.accessToken) return;
        const token = generateToken(aid, accessData.accessToken!);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/user/ban`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'token': token },
                body: JSON.stringify({ user_id: userId })
            });
            if (response.ok) {
                toast(`Ban user_id: ${userId}. Don't forget to unban later.`);
            } else {
                console.log(`Error banning user_id: ${userId}`);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const generateRandomText = (text: string): string =>
        text.replace(/\S/g, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.charAt(Math.random() * 52));



    useEffect(() => {
        if (blurForUser && message.message) setRandomText(generateRandomText(message.message));
    }, [blurForUser, message.message]);



    const messageText = blurForUser ? randomText : message.message;

    return (
        <>
            {message.type === "JOIN" ? (
                <div className={`chat-message-joined no-select ${blurForUser ? 'chat-message-item-blur' : ''}`}>
                    {message.userName} joined
                </div>
            ) : (
                <>
                    <div
                        className={`chat-message-item ${blurForUser ? 'chat-message-item-blur' : ''}`}
                        onClick={handleClick}
                    >
                        <img
                            className={`chat-message-item-image-profile`}
                            src={message.userImageUrl === '' ? userProfile : message.userImageUrl}
                            alt=""
                        />

                        <div className="chat-message-item-message-container">
                            <div>
                                <div className="chat-message-item-text-container">
                                    <div
                                        className={`chat-message-item-text ${message.userId === process.env.REACT_APP_ADMIN_ACCOUNT
                                            ? 'admin'
                                            : message.self
                                                ? 'self'
                                                : 'other'
                                            } no-select `}
                                    >
                                        <div
                                            className={`chat-message-item-username-${message.userId === process.env.REACT_APP_ADMIN_ACCOUNT
                                                ? 'admin'
                                                : message.self
                                                    ? 'self'
                                                    : 'other'
                                                }`}
                                        >
                                            {message.userName}
                                        </div>
                                        {messageText}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {message.self || message.admin ? (
                            <div className="chat-message-item-three-dots-menu-container">
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{ style: { maxHeight: 48 * 4.5, width: '10ch' } }}
                                >
                                    {message.self ? (
                                        options.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                onClick={() => { handleClose(); handleSelect(option.value, message.key, message.userId); }}
                                            >
                                                {option.text}
                                            </MenuItem>
                                        ))
                                    ) : message.admin ? (
                                        adminOptions.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                onClick={() => { handleClose(); handleSelect(option.value, message.key, message.userId); }}
                                            >
                                                {option.text}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </Menu>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default Message;
