import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Message from './Message';
import { Transition } from 'react-spring/renderprops';
import './ChatHistory.css';



const ChatHistory = forwardRef<ChatRef, ChatHistoryProps>((props, ref) => {
    const [autoScroll, setAutoScroll] = useState<boolean>(true);
    const [autoScrollButton, setAutoScrollButton] = useState<ChatAutoScrollButton>({ show: false });
    const [lastScrollPos, setLastScrollPos] = useState<LastScrollPos>({ direction: '', lastScrollPos: 0 });
    const [messageList, setMessageList] = useState<Array<MessageItem>>([]);
    const [showChatContent, setShowChatContent] = useState<boolean>(true);

    const messagesContainer = React.createRef<HTMLDivElement>();
    const messagesEndRef = React.createRef<HTMLDivElement>();



    const checkingAutoButton = (message: string) => {
        if (!autoScroll) {
            setAutoScrollButton({ show: true, lastestText: `"${message}"` });
        }
    };

    const scrollToBottom = () => {
        if (autoScroll && messagesEndRef.current) {
            messagesContainer.current?.scrollTo({ top: messagesContainer.current.scrollHeight });
        }
    };

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const currentScrollPos = event.currentTarget.scrollTop;
        const direction = lastScrollPos.lastScrollPos > currentScrollPos ? 'up' : 'down';
        setLastScrollPos({ direction, lastScrollPos: currentScrollPos });
    };

    const handleAutoScrollUp = () => {
        lastScrollPos.direction === 'up' && setAutoScroll(false);
    };

    const handleAutoScrollButtonClick = () => {
        scrollToBottom();
        setAutoScroll(true);
        setAutoScrollButton({ ...autoScrollButton, show: false });
    };



    useImperativeHandle(ref, (): ChatRef => ({
        fetchMessageList: (messageList: MessageItem[]) => setMessageList(messageList),
        addMessage(message: MessageItem) {
            checkingAutoButton(message.message);
            setMessageList((prevList) => [...prevList, message]);
        },
        deleteMessage(key: string) { setMessageList((prevList) => prevList.filter((m) => m.key !== key)); },
        scrollToBottom() {
            scrollToBottom();
            setAutoScroll(true);
            setAutoScrollButton({ ...autoScrollButton, show: false });
        },
    }));



    useEffect(() => { handleAutoScrollUp(); }, [lastScrollPos]);
    useEffect(() => { scrollToBottom(); }, [messageList, autoScroll]);


    return (
        <div className="chat-container">
            <div className="my-card chat-background">
                <div className="my-card bg-white chat-title-container">
                    <div className="chat-title-text rainbow_text_animated">แชต</div>
                    <div
                        className={`toggle-chat-content chat-button-${showChatContent ? '' : 'rotate'}`}
                        onClick={() => setShowChatContent(!showChatContent)}>
                        ×
                    </div>
                </div>

                {(showChatContent && <div ref={messagesContainer} className="chat-message-container" onScroll={handleScroll}>
                    {messageList.map((m, i) => <Message key={i} message={m} delete={() => { }} />)}
                    <div ref={messagesEndRef} />
                </div>)}

                {showChatContent && (<div className="chat-autoscolling-container">
                    <Transition
                        items={autoScrollButton.show}
                        from={{ opacity: 0, transform: 'translate3d(0, 10px, 0)' }}
                        enter={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
                        leave={{ opacity: 0, transform: 'translate3d(0, 10px, 0)' }}
                    >
                        {(show) => show && ((props) => (
                            <div
                                className="chat-autoscolling-button"
                                style={props}
                                onClick={handleAutoScrollButtonClick}
                            >
                                {autoScrollButton.lastestText}
                            </div>
                        ))}
                    </Transition>
                </div>)}
            </div>
        </div >
    );
});

ChatHistory.displayName = 'Chat';

export default ChatHistory;