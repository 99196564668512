import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { generateToken } from '../../utility/Token';
import { confirmAlert } from 'react-confirm-alert';
import { setTransactionStatus } from '../../stores/shopee-pay/ShopeePayState';
import { reloadTransaction } from '../../stores/transaction/Transaction';
import moment from 'moment';
import 'moment/locale/th';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import './PaymentHistoryList.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import openReceiptIcon from '../../images/actions/open-receipt-icon.svg';

interface PaymentHistoryListProps {
    title: string; transactionList: Array<any>;
}



const PaymentHistoryList: React.FC<PaymentHistoryListProps> = ({ title, transactionList }) => {

    const dispatch = useDispatch();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const [isCheckStatusShopeePay, setIsCheckStatusShopeePay] = useState(false);
    const [isUserInSchool, setIsUserInSchool] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const totalPages = Math.ceil(transactionList.length / itemsPerPage);

    const [premiumStart, setPremiumStart] = useState<string | null>(null);
    const [premiumEnd, setPremiumEnd] = useState<string | null>(null);
    const [productName, setProductName] = useState<string | null>(null);

    const at = accessData.accessToken ? accessData.accessToken : '';
    const aid = accessData.accountId ? accessData.accountId : '';
    const userToken = generateToken(aid, at);
    const headers = {
        'Accept': 'application/json', 'Content-Type': 'application/json', 'token': generateToken(aid, at)
    };

    const indexOfLastTransaction = currentPage * itemsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
    const currentTransactions = transactionList.slice(indexOfFirstTransaction, indexOfLastTransaction);

    const isPendingList = transactionList.every(transaction => ["1", "3", "4"].includes(transaction.status));
    const activeTransaction = currentTransactions.find(transaction => transaction.status === '1');



    const checkTransactionStatusShopeePay = async (transactionId: string) => {
        try {
            setIsCheckStatusShopeePay(true);
            const url = `${process.env.REACT_APP_API_URL_V2}/v1/shopee/check/${transactionId}`;
            const response = await fetch(url, { method: 'POST', headers: headers });
            const apiRes = await response.json();
            if (apiRes.errcode === 0) {
                dispatch(setTransactionStatus(apiRes.api_result.transaction.status));
                dispatch(reloadTransaction(at, aid));
            }
        } catch (error) {
            console.error('Error checking Shopee transaction status:', error);
        } finally {
            setIsCheckStatusShopeePay(false);
        }
    };

    const checkUserInSchool = async () => {
        if (!aid) {
            setIsUserInSchool(false);
            return;
        }
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/school/check-premium/${aid}`;

        try {
            const response = await fetch(url, { method: 'GET', headers: headers });
            if (!response.ok) throw new Error(`Failed to fetch user data. Status: ${response.status}`);

            const apiRes = await response.json();
            if (apiRes?.premiumStart) {
                setPremiumStart(apiRes.premiumStart); setPremiumEnd(apiRes.premiumEnd);
                setProductName(apiRes.productName); setIsUserInSchool(true);
            } else {
                setIsUserInSchool(false);
            }
        } catch (error) {
            console.error('Error checking user in school:', error);
            setIsUserInSchool(false);
        }
    };

    const openPdf = (transactionId: string) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', `${process.env.REACT_APP_API_URL_V2}/v1/receipt/getReceipt/${transactionId}`, true);
        xhr.setRequestHeader('token', userToken);
        xhr.responseType = 'blob';
        xhr.onload = () => {
            if (xhr.status === 200) {
                const url = URL.createObjectURL(xhr.response);
                window.open(url, '_blank');
            }
        };
        xhr.send();
    };

    const cancelSubscribe = async (extTransactionId: string) => {
        try {
            const url = `${process.env.REACT_APP_API_URL_V2}/v1/omise/subscribe/cancel/${extTransactionId}`;
            const response = await fetch(url, { method: 'POST', headers: headers });
            const res = await response.json();
            if (res.error === 0) dispatch(reloadTransaction(at, aid));
        } catch (error) {
            console.error('Error canceling subscription:', error);
        }
    };

    const cancelSubscribeConfirm = (extTransactionId: string, expireTime: string) => {
        confirmAlert({
            customUI: ({ onClose }) => getConfirmLayout(onClose, extTransactionId, expireTime)
        });
    };

    const getConfirmLayout = (onClose: () => void, extTransactionId: string, expireTime: string) => (
        <div className='cancel-confirm-custom-ui'>
            <h1>ต้องการยกเลิก Subscribe?</h1>
            <p>คุณจะยังคงสามารถใช้บริการพรีเมี่ยมจนกว่าจะหมดอายุการใช้งาน</p>
            <p>วันที่ {moment(expireTime).format('LL')}</p>
            <Button variant="secondary" onClick={onClose}>ไม่</Button>
            <Button variant="danger" onClick={() => { cancelSubscribe(extTransactionId); onClose(); }}>ใช่</Button>
        </div>
    );

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(prev => prev + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(prev => prev - 1);
    };

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
    };



    useEffect(() => {
        dispatch(reloadTransaction(at, aid));
        checkUserInSchool();
    }, [aid]);



    return (
        <div className="payment-history-list-container">
            <p className="payment-history-list-title">{title}</p>

            {isPendingList && isUserInSchool && activeTransaction && premiumStart && premiumEnd && (
                <div className="row payment-history-list-item-container">

                    <div className="col-12 col-md-2">
                        <div className="payment-history-list-item-profile-container">
                            <img className="payment-history-list-item-profile-image" src={accessData.account.lowPhotoUrl} alt="User Profile Image" />
                            <div className="payment-history-list-item-profile-detail-container">
                                <p className="payment-history-list-item-profile-fullname-text">{accessData.account.fullname}</p>
                                <p className="payment-history-list-item-profile-login-text">{accessData.account.login}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-2 payment-history-list-item-product-container">
                        <p className="payment-history-list-item-product-description">{productName}</p>
                        <p className="payment-history-list-item-amount-text"></p>
                    </div>

                    <div className="col-12 col-md-8">
                        <div className="row h-100">
                            <div className="col-12 col-md-3 p-0">
                                <div className="payment-history-list-item-transaction-container">
                                    <p className="payment-history-list-item-transaction-date-text">{premiumStart}</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 p-0">
                                <div className="payment-history-list-item-transaction-container">
                                    <p className="payment-history-list-item-transaction-date-text">{premiumEnd}</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 p-0">
                                <div className="payment-history-list-item-transaction-container">
                                    <p className="payment-history-list-item-transaction-success-text">กำลังใช้งานอยู่ (ชำระเงินแล้ว)</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 p-0">
                                <div className="payment-history-list-item-transaction-container">
                                </div>
                            </div>
                            <div className="col-12 col-md-2 p-0">
                                <div className="payment-history-list-item-transaction-container cancle-button-payment-history">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )}

            {currentTransactions.length > 0 && accessData.accountId ? (
                currentTransactions.map((transaction) => (
                    <div key={transaction.transaction_id} className="row payment-history-list-item-container">

                        <div className="col-12 col-md-2">
                            <div className="payment-history-list-item-profile-container">
                                <img className="payment-history-list-item-profile-image" src={accessData.account.lowPhotoUrl} alt="User Profile Image" />
                                <div className="payment-history-list-item-profile-detail-container">
                                    <p className="payment-history-list-item-profile-fullname-text">{accessData.account.fullname}</p>
                                    <p className="payment-history-list-item-profile-login-text">{accessData.account.login}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-2 payment-history-list-item-product-container">
                            <p className="payment-history-list-item-product-description">{transaction.product_description_th}</p>
                            <p className="payment-history-list-item-amount-text">{formatCurrency(transaction.amount)} บาท</p>
                        </div>

                        <div className="col-12 col-md-8">
                            <div className="row h-100">
                                {['create_time', 'expire_time'].map((timeKey) => (
                                    <div key={timeKey} className="col-12 col-md-3 p-0">
                                        <div className="payment-history-list-item-transaction-container">
                                            <p className="payment-history-list-item-transaction-date-text">{moment(transaction[timeKey]).format('LL')}</p>
                                        </div>
                                    </div>
                                ))}
                                <div className="col-12 col-md-2 p-0">
                                    <div className="payment-history-list-item-transaction-container">
                                        {transaction.status === '1' && <p className="payment-history-list-item-transaction-success-text">กำลังใช้งานอยู่ (ชำระเงินแล้ว)</p>}
                                        {transaction.status === '2' && <p className="payment-history-list-item-transaction-gray-text">ไม่ได้ใช้แล้ว หรือหมดอายุแล้ว</p>}
                                        {transaction.status === '3' && <Link to="/pay"><p className="payment-history-list-item-transaction-pending-text">ชำระเงิน</p></Link>}
                                        {transaction.status === '4' && <p className="payment-history-list-item-transaction-confirm-text">รอตรวจสอบการชำระเงิน</p>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 p-0">
                                    <div className="payment-history-list-item-transaction-container">
                                        {['1', '2', '7'].includes(transaction.payment_method) && transaction.confirm_payment_time != null && (
                                            <img className="open-receipt-button" src={openReceiptIcon} alt="nockacademy plan get receipt" onClick={() => openPdf(transaction.transaction_id)} />
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 p-0">
                                    <div className="payment-history-list-item-transaction-container cancle-button-payment-history">
                                        {(transaction.payment_method === '7' && transaction.auto_renew === '1') && (
                                            <Button variant="outline-danger" onClick={() => cancelSubscribeConfirm(transaction.ext_transaction_id, transaction.expire_time)}>ยกเลิก</Button>
                                        )}
                                        {transaction.payment_method === '10' && transaction.status === '4' && (
                                            <>
                                                {isCheckStatusShopeePay ? <Spinner animation="border" variant="primary" /> : <Button variant="link" onClick={() => checkTransactionStatusShopeePay(transaction.transaction_id)}>เช็คสถานะ</Button>}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                ))
            ) : (
                <div className="row payment-history-list-item-container">
                    <p className="payment-history-list-empty-item m-0">ไม่มีประวัติการทำรายการ</p>
                </div>
            )}

            {currentTransactions.length >= itemsPerPage && (
                <div className="payment-history-pagination-controls">
                    <Button disabled={currentPage === 1} onClick={handlePreviousPage}>ก่อนหน้า</Button>
                    <span>{` หน้า ${currentPage} จาก ${totalPages} `}</span>
                    <Button disabled={currentPage === totalPages} onClick={handleNextPage}>ถัดไป</Button>
                </div>
            )}

        </div>
    );
};

export default PaymentHistoryList;
