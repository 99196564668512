import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import badge from '../../images/promote/badge.svg';
import bank from '../../images/logo/bank.svg';
import credit from '../../images/logo/credit.svg';
import promptPay from '../../images/logo/prompt_pay.svg';

interface Product {
    product_id: number;
    is_promote: string;
    description_th: string;
    save_off_text: string;
    subscription_months: number;
    price: number;
    is_bank_transfer: boolean;
}

interface ProductItemProps { product: Product; }



const ProductItem: React.FC<ProductItemProps> = ({ product }) => {

    const location = useLocation();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);

    const isMobilePage = location.pathname === '/mobile-1';



    const handleItemClick = () => {
        if (isMobilePage) {
            const redirectUrl = accessData.accessToken
                ? `${process.env.REACT_APP_WEB_URL}/pay-mobile?mobile-1=${product.product_id}`
                : `${process.env.REACT_APP_WEB_URL}/pay-mobile-guest?mobile-1=${product.product_id}`;
            window.location.href = redirectUrl;
        } else {
            window.location.href = `${process.env.REACT_APP_WEB_URL}/pay/${product.product_id}?come_from=learnPage`;
        }
    };



    return (
        <div className="col-12 col-md-6 col-xl-4 p-0 main-course">
            <div className="course-item" onClick={handleItemClick}>
                {product.is_promote === "1" && <img src={badge} className="badge-img" />}

                <div className="product-description">{product.description_th}</div>
                <div className="bargain-description">{product.save_off_text}</div>

                {product.save_off_text ? (
                    <div className="bargain-container">
                        <u className="bargain-old-price">
                            {(550 * product.subscription_months).toLocaleString(undefined, { maximumFractionDigits: 2 })} ฿
                        </u>
                    </div>
                ) : (
                    <div className="bargain-container-blank"></div>
                )}

                <div className="price-main"><b>{parseInt(product.price.toString()).toLocaleString()} ฿</b></div>

                <div>{(product.price / product.subscription_months).toLocaleString(undefined, { maximumFractionDigits: 2 })} บาท / เดือน</div>

                <div className="payment-description">
                    {isMobilePage ? (
                        <div className="course-item-image-container mobile-1-course-item-image">
                            <img src={promptPay} className="course-item-image" alt="PromptPay" />
                        </div>
                    ) : (
                        <>
                            <div>บัตรเครดิต</div>
                            {product.is_bank_transfer && <div>โอนผ่านธนาคาร</div>}
                            {product.is_bank_transfer && <div>พร้อมเพย์</div>}
                        </>
                    )}
                </div>

                <div className="subscribe-button">สมัคร</div>
            </div>

            {!isMobilePage && (
                <div className="course-item-images">
                    <div className="course-item-image-container">
                        <img src={credit} className="course-item-image" alt="Credit Card" />
                    </div>

                    {product.is_bank_transfer && (
                        <div className="course-item-image-container">
                            <img src={bank} className="course-item-image" alt="Bank Transfer" />
                        </div>
                    )}

                    {product.is_bank_transfer && (
                        <div className="course-item-image-container">
                            <img src={promptPay} className="course-item-image" alt="PromptPay" />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ProductItem;
