import React from 'react';
import { useHistory } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import CoursePrice from '../../components/course-price/CoursePrice';
import './Mobile1.css';
import Artwork1 from "../../images/mobile-1/Artwork1.png";
import Artwork2 from "../../images/mobile/Artwork2.jpg";
import Artwork3 from "../../images/mobile/Artwork3.png";
import Artwork4 from "../../images/mobile-1/Artwork4.png";
import Artwork6 from "../../images/mobile/Artwork6.jpg";
import Artwork7 from "../../images/mobile/Artwork7.jpg";
import Artwork8 from "../../images/mobile/Artwork8.jpg";



const Mobile1 = () => {

    const history = useHistory();

    const YouTubeVideo1 = 'https://youtu.be/U3qV6JtctMg';
    const YouTubeVideo2 = 'https://youtu.be/OBb4Hg1VjDM';

    const artworksAndVideos = [
        Artwork1, YouTubeVideo1, Artwork2, Artwork3, YouTubeVideo2, Artwork4, Artwork6, Artwork7, Artwork8
    ];



    if (isBrowser) history.push('/');

    return (
        <div className="mobile-container mobile-1-container">

            {artworksAndVideos.map((item, index) => (
                item.includes('youtu.be') ? (
                    <div key={index} className="video-container">
                        <iframe
                            width="100%" height="315"
                            src={`https://www.youtube.com/embed/${item.split('youtu.be/')[1]}`}
                            title={`YouTube Video Player ${index}`} frameBorder="0" allowFullScreen
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                    </div>
                ) : (
                    <img key={index} src={item} alt={`Artwork ${index + 1}`} className="artwork" />
                )
            ))}

            <CoursePrice />

        </div>
    );
};

export default Mobile1;
