import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { generateToken } from '../../utility/Token';
import ProductItem from './ProductItem';
import './CoursePrice.css';
import arrowBlack from '../../images/actions/arrow-black.svg';



const CoursePrice = () => {

    const location = useLocation();
    const courseListContainer = useRef<HTMLDivElement>(null);
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);

    const [isScrollPaused, setIsScrollPaused] = useState(false);
    const [productList, setProductList] = useState<Array<any>>([]);
    const [scroll, setScroll] = useState(0);
    const [scrollIndex, setScrollIndex] = useState(0);
    const [scrollTotalPage, setScrollTotalPage] = useState(0);
    const [touchStartX, setTouchStartX] = useState<number | null>(null);

    const isMobilePage = location.pathname === '/mobile-1';



    const reloadTransaction = async (accessToken: string, accountId: string) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/api/v4/method/paymentSystem?access_token=${generateToken(accountId, accessToken)}&accountId=${accountId}&all=true`;
            const response = await fetch(url);
            if (!response.ok) throw new Error(`Failed to fetch payment system. Status: ${response.status}`);
            const { product_list } = await response.json();
            setProductList(product_list.sort((a: { product_id: number }, b: { product_id: number }) => b.product_id - a.product_id));
        } catch (error) {
            console.error(`Cannot load payment system: ${(error as Error).message}`);
        }
    };

    const scrollBy = (direction: number) => {
        if (!courseListContainer.current) return;

        const clientWidth = courseListContainer.current.clientWidth;
        const maxScroll = (scrollTotalPage - 1) * clientWidth;

        let newScroll = scroll + direction * clientWidth;
        if (newScroll < 0) {
            newScroll = maxScroll;
            setScrollIndex(scrollTotalPage - 1);
        } else if (newScroll > maxScroll) {
            newScroll = 0;
            setScrollIndex(0);
        } else {
            const newScrollIndex = Math.floor(newScroll / clientWidth);
            setScrollIndex(newScrollIndex);
        }

        courseListContainer.current.scroll({ left: newScroll });
        setScroll(newScroll);
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        setTouchStartX(event.touches[0].clientX);
        setIsScrollPaused(true);
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        if (!touchStartX) return;

        const touchDeltaX = event.touches[0].clientX - touchStartX;
        const direction = touchDeltaX > 0 ? -1 : 1;

        if (Math.abs(touchDeltaX) >= 50) {
            scrollBy(direction);
            setTouchStartX(null);
            setIsScrollPaused(false);
        }
    };



    useEffect(() => {
        const { accessToken, accountId } = accessData;
        reloadTransaction(`${accessToken}`, `${accountId}`);
    }, []);

    useEffect(() => {
        if (productList.length > 0 && courseListContainer.current) {
            const promotedProductIndex = productList.findIndex((product) => product.is_promote === "1");
            if (promotedProductIndex !== -1) {
                const clientWidth = courseListContainer.current.clientWidth;
                const newScroll = promotedProductIndex * clientWidth;
                setScrollIndex(promotedProductIndex);
                courseListContainer.current.scrollLeft = newScroll;
                setScroll(newScroll);
            } else {
                const clientWidth = courseListContainer.current.clientWidth;
                courseListContainer.current.scrollLeft = clientWidth;
            }
        }
    }, [productList]);

    useEffect(() => {
        if (courseListContainer) {
            const childElementCount = courseListContainer.current!.childElementCount;
            const columnsPerPage = window.innerWidth >= 1200 ? 3 : window.innerWidth >= 768 ? 2 : 1;
            const totalPageRoundUp = Math.ceil(childElementCount / columnsPerPage);
            setScrollTotalPage(totalPageRoundUp);
        }
    }, [scroll, productList]);

    useEffect(() => {
        if (isMobilePage) return;
        const intervalDuration = window.innerWidth < 768 ? 3000 : 6000;
        let interval: NodeJS.Timeout | null = null;

        if (!isScrollPaused) interval = setInterval(() => { scrollBy(1); }, intervalDuration);
        return () => { if (interval) clearInterval(interval); };

    }, [scrollBy, isScrollPaused, isMobilePage]);



    return (
        <div className="course-price-card">
            <div className="course-price-header">
                {productList.length > 3 && (
                    <img
                        className="course-list-scroll-left-button" style={{ width: "44px", height: "44px" }}
                        src={arrowBlack} alt="Left Button" onClick={() => { scrollBy(-1); }}
                    />
                )}
                <div className="course-price-title rainbow_text_animated">บริการของเรา</div>
                {productList.length > 3 && (
                    <img
                        className="course-list-scroll-right-button" style={{ width: "44px", height: "44px" }}
                        src={arrowBlack} alt="Right Button" onClick={() => { scrollBy(1); }}
                    />
                )}
            </div>

            <div className="course-price-description">
                เรียนได้ครบสี่วิชา วิทย์ คณิต อังกฤษ ไทย<br />
                มีบทเรียนมากกว่า <b style={{ color: "#f97d5e" }}>2,000+ คลิป, แบบฝึกหัด ข้อสอบ 4,000+ บท, ไลฟ์สอนสด, ไลฟ์ย้อนหลัง</b><br />
                <b style={{ color: "#f97d5e" }}>รับชมไลฟ์สอนสด</b> และ <b style={{ color: "#f97d5e" }}>เข้าทำข้อสอบ</b> ได้ทุกอุปกรณ์
            </div>

            <div className="course-price-scrollable-container" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                <div className="course-list-container all-course row" ref={courseListContainer}>
                    {productList.map((product, index) => (<ProductItem key={index} product={product} />))}
                </div>

                <div className="course-price-index-container">
                    {Array.from({ length: scrollTotalPage }, (_, i) => (
                        <div key={i} className={`course-price-index-point${scrollIndex === i ? '-active' : ''}`} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CoursePrice;
